<template>
  <div>
    <v-progress-circular
      v-if="loading"
      :size="100"
      :width="4"
      color="fmq_black"
      indeterminate
      class="d-flex mx-auto py-8 mt-8"
    ></v-progress-circular>
    <v-form v-else ref="form" v-model="valid" lazy-validation class="pt-4">
      <v-row class="px-6 pt-2 justify-center">
        <v-col cols="10">
          <v-text-field
            v-model="formData.nome"
            placeholder="Nome"
            outlined
            dense
            :clearable="true"
            label="Nome"
            required
            :rules="rules.genericRules"
          >
          </v-text-field>
          <v-text-field
            v-model="formData.email"
            placeholder="Email"
            outlined
            dense
            :clearable="true"
            label="Email"
            required
            :rules="rules.emailRules"
          >
          </v-text-field>
          <v-text-field
            v-model="formData.crm"
            placeholder="Número do CRM"
            outlined
            dense
            :clearable="true"
            label="Número do CRM"
            required
            :rules="rules.genericRules"
          >
          </v-text-field>
          <v-autocomplete
            v-model="formData.crmUf"
            :items="estados"
            hide-no-data
            hide-selected
            item-text="text"
            item-value="value"
            placeholder="Digite o estado"
            outlined
            dense
            :clearable="true"
            label="Estado"
            required
            :rules="rules.selectRules"
          ></v-autocomplete>

          <v-autocomplete
            v-model="formData.especialidades"
            :items="listEspecialidades"
            hide-no-data
            item-text="nome"
            item-value="id"
            placeholder="Especialidade"
            outlined
            dense
            clear
            multiple
            small-chips
            chips
            label="Especialidades"
            required
            :rules="rules.multiSelectRules"
            deletable-chips
          ></v-autocomplete>
          <v-checkbox
            v-model="formData.aceiteTermosUso"
            class="ma-0"
            :hide-details="true"
          >
            <template v-slot:label>
              <div>
                Autorizo a utilização dos meus dados para a formalização do
                contato, conforme
                <a
                  target="_blank"
                  :href="urlTermo"
                  @click.stop
                >
                  Aviso de Privacidade</a
                >
                ferramenta
              </div>
            </template></v-checkbox
          >
          <v-btn
            depressed
            color="fmq_gray mt-4"
            dark
            :loading="loadingBtn"
            :disabled="!formData.aceiteTermosUso"
            @click="editar"
            >Atualizar</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
    <AlertSuccess
      :dialog="success"
      dialogMessage="Dados editados com sucesso"
      @close="success = false"
    />
    <AlertError :alertError="error" :messageError="messageError" />
  </div>
</template>

<script>
import { ESTADOS_LIST } from "@/utils/constants.js";
import { rules } from "@/utils/rules.js";
import {
  exibirDadosMedico,
  listarEspecialidadeMedico,
  editarDadosMedico,
} from "@/services/painel.js";
export default {
  name: "MeusDados",
  data: () => ({
    formData: {
      id: "",
      nome: "",
      email: "",
      aceiteTermosUso: null,
      crm: "",
      crmUf: "",
      especialidades: [],
      hash: "",
    },
    urlTermo: process.env.VUE_APP_API + "donwload/termo",
    loading: true,
    valid: true,
    rules: rules,
    estados: ESTADOS_LIST,
    listEspecialidades: [],
    loadingBtn: false,
    success: false,
    error: false,
    messageError: null,
  }),
    beforeCreate() {
    if(this.$store.state.user.data.tipo === "Representante") {
      this.$router.push({path: '/'})
    }
  },
  created() {
    this.exibir(this.$route.params.hash);
    this.listEspecialidade();
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    async exibir(hash) {
      try {
        this.loading = true;
        const resp = await exibirDadosMedico(hash);
        this.formData = resp.data;
        this.formData.crmUf = this.formData.crm.split("-")[1];
        this.formData.especialidades = resp.data.especialidades.map(
          (item) => item.id
        );
      } catch (e) {
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    async editar() {
      const payload = await this.formatPayload();
      this.error = false;
      this.sucess = false;
      this.loadingBtn = true;
      try {
        await editarDadosMedico(this.$route.params.hash, payload).then(() => {
          this.success = true;
        });
        this.loadingBtn = false;
      } catch (e) {
        this.loadingBtn = false;
        this.error = true;
        this.messageError = e.response.data.message;
      }
    },
    async listEspecialidade() {
      const resp = await listarEspecialidadeMedico();
      this.listEspecialidades = resp.data;
    },
    formatPayload() {
      let dados_enviados = {
        id: this.formData.id,
        nome: this.formData.nome,
        email: this.formData.email,
        aceiteTermosUso: this.formData.aceiteTermosUso,
        crm: this.formData.crm + "-" + this.formData.crmUf,
        especialidades: this.formData.especialidades,
        hash: this.formData.hash,
      };
      return dados_enviados;
    },
  },
  computed: {
    formValid() {
      return this.$refs.form.validate();
    },
  },
};
</script>

<style></style>
