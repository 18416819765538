<template>
  <div>
    <v-progress-circular
      v-if="loading"
      :size="100"
      :width="4"
      color="fmq_black"
      indeterminate
      class="d-flex mx-auto py-8 mt-8"
    ></v-progress-circular>
    <v-form v-else ref="form" class="pt-4">
      <v-row class="px-6 pt-2 justify-center">
        <v-col cols="10">
          <!-- <v-text-field
            v-model="formData.medicoSolicitante.nome"
            placeholder="Médico"
            outlined
            dense
            label="Médico"
            readonly
          >
          </v-text-field>

          <v-text-field
            v-model="formData.produto.nome"
            placeholder="Produto"
            outlined
            dense
            label="Produto"
            readonly
          >
          </v-text-field>

          <v-text-field
            v-model="formData.representante.nome"
            placeholder="Representante"
            outlined
            dense
            label="Representante"
            readonly
          >
          </v-text-field>

          <v-text-field
            v-model="formData.assunto"
            placeholder="Motivo"
            outlined
            dense
            label="Motivo"
            readonly
          >
          </v-text-field>

          <v-textarea
            v-model="formData.conteudo"
            placeholder="Relato"
            outlined
            dense
            label="Relato"
            readonly
          >
          </v-textarea> -->

          <p class="caption mb-0">Resposta:</p>
          <div
            class="pa-3 rounded mb-4"
            style="border: 1px solid rgba(0, 0, 0, 0.38)"
          >
            <div v-html="formData.resposta"></div>
          </div>

          <p class="caption mb-1">Anexos:</p>
          <div v-for="(arquivo, key) in formData.anexos" :key="key">
              <v-chip
                class="mr-2 px-6"
                color="fmq_gray"
                :href="arquivo.linkDownload"
                target="_blank"
                dark
              >
              <v-icon left small> mdi-file </v-icon>
                {{ arquivo.titulo }}
              </v-chip>
          </div>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { visualizarSolicitacao } from "@/services/painel.js";
export default {
  name: "MinhaSolicitacao",
  data: () => ({
    loading: false,
    formData: {},
  }),
    beforeCreate() {
    if(this.$store.state.user.data.tipo === "Representante") {
      this.$router.push({path: '/'})
    }
  },
  created() {
    this.exibir(this.$route.params.hash);
  },
  methods: {
    async exibir(hash) {
      try {
        this.loading = true;
        const resp = await visualizarSolicitacao(hash);
        this.formData = resp.data;
      } catch (e) {
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style></style>
